import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/prj/sepci0/chronoblog-site/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <h1 {...{
      "id": "yes-i-own-a-website-i-create-them"
    }}>{`Yes i own a website. I create them.`}</h1>
    <p>{`Here you can find some (most) of my projects.`}</p>
    <p>{`If you need anyhting - don't be afraid to messege me!`}</p>
    <p>&nbsp;</p>
    <h3 {...{
      "id": "more-about-me-and-my-projects"
    }}>{`More about me and my projects!`}</h3>
    <FeedItems yearSeparator={false} itemsFormat="compact" filterByTags={['link']} mdxType="FeedItems" />
    <p>&nbsp;</p>
    <h3 {...{
      "id": "blog-posts"
    }}>{`Blog posts`}</h3>
    <p>{`I always wanted to blog. Never made it a habbit :(`}</p>
    <FeedItems itemsFormat="compact" filterByTags={['post', 'article']} limit={5} mdxType="FeedItems" />
    <p>&nbsp;</p>
    <h3 {...{
      "id": "small-projects"
    }}>{`Small projects`}</h3>
    <p>{`These are stuff for fun, my friends, or i just need to have it somewhere in public.`}</p>
    <FeedItems itemsFormat="compact" filterByTags={['project']} mdxType="FeedItems" />
    <p>&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      